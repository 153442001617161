import React, { useState, useEffect }             from "react";
import { useSelector, useDispatch }    from 'react-redux'
import { Form, Container, Button, Row, Col } from 'react-bootstrap';
import PaymentSelectionCard from "./payments/PaymentSelectionCard";
import TitleRow      from "../shared/TitleRow";
import BadgeText     from "../shared/BadgeText";
import FormAlert     from "../shared/FormAlert"
import SubmitButton  from "../shared/SubmitButton"
import SpinnerScreen                    from "../shared/SpinnerScreen"
import { withTranslation } from 'react-i18next';
import { rateFinalQuote }   from '../../actions/rates'
import PayinFullModal from '../../components/bind-online/payments/PayInFullModal'
import { checkState } from "../../services/state-check";
import { Helmet } from 'react-helmet'

function useGetRate(quoteId) {
  const dispatch  = useDispatch()
  const { rates } = useSelector(state => state.data)
  const [rate, setRate] = useState(undefined)

  useEffect(() => {
    if (!rates.length) {
      dispatch(rateFinalQuote(quoteId))
    } else {
      setRate(rates[0])
    }
  }, [rates, dispatch, quoteId])
  return rate
}

const Payments = ({ history, t }) => {
  const { quote }                                   = useSelector(state => state.data)
  const rate                                        = useGetRate(quote.id)
  const [errors, setErrors]                         = useState([])
  const [submitted, setSubmitted]                   = useState(false)
  
  const [paymentOption, setPaymentOption]           = useState([])
  const paymentOptionProps                          = { paymentOption, setPaymentOption }
  const [paymentOptions, setPaymentOptions]         = useState([])
  const [showPayInfullModal, setShowPayInfullModal] = useState(false)


  useEffect(() => {
    if (rate) {
      if (rate.payment_options[0].plan_type === 'pay_in_full') {
        setPaymentOptions(rate.payment_options.reverse())
      }
      else {
        setPaymentOptions(rate.payment_options)
      }
    }
  }, [rate, paymentOptions]);


  function handleSubmit(event) {
    event.preventDefault()
    setSubmitted(true)  
    const payment_plan_code = paymentOption.plan_code || paymentOption.plan_description

    const validationErrors = []
  
    let payment_plan_error = [];
    if (!payment_plan_code) {
      validationErrors[payment_plan_error] = ['SELECT A PLAN']
      setErrors(err => Object.values(validationErrors).flat())
      window.scrollTo({ top: 0, behavior: "smooth" })
    }else {
      quote.selected_pay_plan = rate.payment_options.find(plan => plan.plan_code === payment_plan_code || plan.plan_description === payment_plan_code )
      if (checkState()){
        history.push('/bol/online-payment')
      }else{
        history.push('/bol/card-details')
      }
    }
  }

  // useEffect(() => {
  //   if (!submitted && bindingQuote) { // flag submission
  //     setErrors([])
  //     setSubmitted(true)
  //   } else if (!bindingQuote & submitted && quote.errors) { // display errors after submission has finished
  //     setErrors([...errors, quote.errors])
  //     setSubmitted(false)
  //     window.scrollTo({ top: 0, behavior: "smooth" })
  //   } else if (submitted && !bindingQuote) { 
  //     history.push('/bol/signatures')
  //   }
  // }, [bindingQuote, submitted, history, quote.errors, errors])

  const cancelAndReturn = (e) => {
    e.preventDefault()
    history.push(`/bol/quotes/${quote.id}/rates`)
  }

  if (!rate) {
    return <SpinnerScreen title="Setting up your payment" />
  } else
    return (
    <Container className="pt-base">
      <Helmet>
        <title>Policy payment | InsureOnline.com</title>
      </Helmet>
      <Form onSubmit={handleSubmit}>
            { !!errors.length && errors.map((err, index) =>
                 <Row className='justify-content-center mb-5' key={`error-${index}`}><Col lg={6} ><FormAlert  text={err}/></Col>  </Row>
            )}
        <TitleRow
          title={t("payments.title")}
          subtitle={t("payments.subtitle")}
        />
        <div className="mb-5">
          { paymentOptions.map((option, index) =>
            <PaymentSelectionCard {...paymentOptionProps}
              option={option} key={option.plan_code} index={index} rate={rate} showPayInfullModal={showPayInfullModal} setShowPayInfullModal={setShowPayInfullModal}/>
          )}
        </div>

        <Row className="justify-content-center">
          <Col lg={5}>
            <SubmitButton text={t("payments.saveAndContinue")} disabled={paymentOption.plan_type === "pay_in_full" && checkState()} showSpinner={submitted}/>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col lg={5} className="d-flex justify-content-center mb-5">
            <Button onClick={cancelAndReturn} variant="link" type="submit" className="text-med-dark text-decoration-none">{t("payments.cancelAndReturn")}</Button>
          </Col>
          <BadgeText />
        </Row>
        </Form>

        {paymentOption.plan_type === "pay_in_full" && checkState() && <PayinFullModal showPayInfullModal={showPayInfullModal} setShowPayInfullModal={setShowPayInfullModal} quoteNumber={quote.quote_number}/>}
    </Container>
  );
};

export default withTranslation(['rates'])(Payments);