import React, { useEffect, useCallback } from "react";
import { withTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux'
import { bindQuote } from '../../../actions/quotes';
import SpinnerScreen from '../../shared/SpinnerScreen';

const config = {
  'tenant': 'tenant.pris',
  'apiUrl': 'https://midas.api.omega2-andromeda.guidewire.net',
  'carrierCode': 'IIC',
  'uiDisplayConfirmation': true,
  'featureCustomAddressCollection': 'ONEINC',
};

const GuidewirePay = ({ history, t }) => {
  const { quote } = useSelector(state => state.data)
  const dispatch = useDispatch()

  // Memoize configs to prevent unnecessary rerenders
  const configs = React.useMemo(() => {
    const carrierConfigs = { ...config };
    const carrier_id = quote.selected_rate.carrier_id
    const stateCode = quote.selected_rate.carrier_product_state_code

    if (carrier_id === 'FCIC') {
      carrierConfigs.paymentConfigId = 717757
      carrierConfigs.accountId = carrier_id
    } else if (carrier_id === 'TXR' && stateCode === "TX") {
      carrierConfigs.paymentConfigId = 46051
      carrierConfigs.accountId = carrier_id
    } else if (carrier_id === 'USHC') {
      carrierConfigs.paymentConfigId = 398853
      carrierConfigs.accountId = carrier_id
    }

    return carrierConfigs;
  }, [quote.selected_rate.carrier_id, quote.selected_rate.carrier_product_state_code]);

  // Load Guidewire Payments SDK
  const loadSdkScript = useCallback(() => {
    return new Promise((resolve, reject) => {
      // Check if SDK is already loaded
      if (window.GuidewirePayments) {
        resolve(window.GuidewirePayments);
        return;
      }

      const script = document.createElement('script');
      script.src = "https://js-sdk-prod.payments.guidewire.net/v1/gw-payments-sdk.js";
      script.async = true;

      script.onload = () => {
        resolve(window.GuidewirePayments);
      };

      script.onerror = () => {
        console.error('Failed to load Guidewire Payments SDK');
        reject(new Error('SDK Load Failed'));
      };

      document.body.appendChild(script);
    });
  }, []);

  // Initialize payment
  const initializePayment = useCallback((sdkInstance) => {
    // Redirect if no selected payment plan
    if (!quote.selected_pay_plan) {
      history.push(`/bol/quotes/${quote.id}/rates`);
      return;
    }

    const sdk = sdkInstance.createSDK(configs);
    sdk.collectAndAssociateCard((result) => {
      const payment_plan_code = quote.selected_pay_plan.plan_code;
      const system = "GW";
      
      switch (result.type) {
        case 'CARD_ADDED':
          history.push('/bol/signatures');
          dispatch(bindQuote({ ...quote, payment_plan_code }, { ...result, system }));
          break;
        case 'CANCELED':
          history.push(`/bol/quotes/${quote.id}/rates`);
          break;
        case 'ERROR':
          alert("There is an error while getting documents");
          break;
        case 'CARD_TOKENIZED':
          break;
        default:
          console.warn('Unexpected response type from JS SDK', result);
      }
    });
  }, [configs, dispatch, history, quote]);

  useEffect(() => {
    // Load SDK and then initialize payment
    loadSdkScript()
      .then((sdkInstance) => {
        initializePayment(sdkInstance);
      })
      .catch((error) => {
        console.error('Payment initialization failed', error);
        history.push(`/bol/quotes/${quote.id}/rates`);
      });
  }, [loadSdkScript, initializePayment, quote.id, history]);
    
  return (
    <div>
      <SpinnerScreen title="Loading for Payment" />
    </div>
  );
};

export default withTranslation(['common'])(GuidewirePay);