const empCategories = [
    { label: "Account Executive", value: "accountexecutive", index: 0 },
    { label: "Accountant/Auditor", value: "accountantauditor", index: 1 },
    { label: "Actor", value: "actor", index: 2 },
    { label: "Actuarial Clerk", value: "actuarialclerk", index: 3 },
    { label: "Actuary", value: "actuary", index: 4 },
    { label: "Administrative Assistant", value: "administrativeassistant", index: 5 },
    { label: "Agent/Broker", value: "agentbroker", index: 6 },
    { label: "Agriculture Inspector/Grader", value: "agricultureinspectorgrader", index: 7 },
    { label: "Air Traffic Control", value: "airtrafficcontrol", index: 8 },
    { label: "Airport Operations Crew", value: "airportoperationscrew", index: 9 },
    { label: "Airport Security Officer", value: "airportsecurityofficer", index: 10 },
    { label: "Analyst", value: "analyst", index: 11 },
    { label: "Animal Control Officer", value: "animalcontrolofficer", index: 12 },
    { label: "Announcer/Broadcaster", value: "announcerbroadcaster", index: 13 },
    { label: "Appraiser - Real Estate", value: "appraiserrealestate", index: 14 },
    { label: "Architect", value: "architect", index: 15 },
    { label: "Artist/Animator", value: "artistanimator", index: 16 },
    { label: "Assistant-Medic/Dent/Vet", value: "assistantmedicdentvet", index: 17 },
    { label: "Athlete", value: "athlete", index: 18 },
    { label: "Attorney", value: "attorney", index: 19 },
    { label: "Audio-Visual Tech", value: "audiovisualtech", index: 20 },
    { label: "Author/Writer", value: "authorwriter", index: 21 },
    { label: "Bailiff", value: "bailiff", index: 22 },
    { label: "Baker", value: "baker", index: 23 },
    { label: "Bartender", value: "bartender", index: 24 },
    { label: "Bellhop", value: "bellhop", index: 25 },
    { label: "Boiler Operator/Maker", value: "boileroperatormaker", index: 26 },
    { label: "Bookkeeper", value: "bookkeeper", index: 27 },
    { label: "Branch Manager", value: "branchmanager", index: 28 },
    { label: "Bricklayer/Mason", value: "bricklayermason", index: 29 },
    { label: "Building Maintenance Engineer", value: "buildingmaintenanceengineer", index: 30 },
    { label: "Bus Person", value: "busperson", index: 31 },
    { label: "Buyer", value: "buyer", index: 32 },
    { label: "Camp Counselor/Lead", value: "campcounselorlead", index: 33 },
    { label: "Caregiver", value: "caregiver", index: 34 },
    { label: "Carpenter", value: "carpenter", index: 35 },
    { label: "Carpet Installer", value: "carpetinstaller", index: 36 },
    { label: "Cashier/Checker", value: "cashierchecker", index: 37 },
    { label: "Caterer", value: "caterer", index: 38 },
    { label: "Chef", value: "chef", index: 39 },
    { label: "Chemist", value: "chemist", index: 40 },
    { label: "Chief Executive", value: "chiefexecutive", index: 41 },
    { label: "Child/Daycare Worker", value: "childdaycareworker", index: 42 },
    { label: "Chiropractor", value: "chiropractor", index: 43 },
    { label: "Choreography/Dancer", value: "choreographydancer", index: 44 },
    { label: "Claims Adjuster", value: "claimsadjuster", index: 45 },
    { label: "Clergy", value: "clergy", index: 46 },
    { label: "Clerk", value: "clerk", index: 47 },
    { label: "Client Care Worker", value: "clientcareworker", index: 48 },
    { label: "Clinical Data Coordinator", value: "clinicaldatacoordinator", index: 49 },
    { label: "Coach", value: "coach", index: 50 },
    { label: "Collections", value: "collections", index: 51 },
    { label: "Commissioner", value: "commissioner", index: 52 },
    { label: "Composer/Director", value: "composerdirector", index: 53 },
    { label: "Concessionaire", value: "concessionaire", index: 54 },
    { label: "Concierge", value: "concierge", index: 55 },
    { label: "Concrete Worker", value: "concreteworker", index: 56 },
    { label: "Construction - Project Manager", value: "constructionprojectmanager", index: 57 },
    { label: "Construction Worker", value: "constructionworker", index: 58 },
    { label: "Consultant", value: "consultant", index: 59 },
    { label: "Contractor", value: "contractor", index: 60 },
    { label: "Controller", value: "controller", index: 61 },
    { label: "Cook-Restaurant/Cafeteria", value: "cookrestaurantcafeteria", index: 62 },
    { label: "Corrections Officer", value: "correctionsofficer", index: 63 },
    { label: "Council Member", value: "councilmember", index: 64 },
    { label: "Counselor", value: "counselor", index: 65 },
    { label: "Court Clerk/Reporter", value: "courtclerkreporter", index: 66 },
    { label: "Crane Operator", value: "craneoperator", index: 67 },
    { label: "Curator", value: "curator", index: 68 },
    { label: "Custodian/Janitor", value: "custodianjanitor", index: 69 },
    { label: "Customer Service Representative", value: "customerservicerepresentative", index: 70 },
    { label: "Delivery Person", value: "deliveryperson", index: 71 },
    { label: "Dental Hygienist", value: "dentalhygienist", index: 72 },
    { label: "Dentist", value: "dentist", index: 73 },
    { label: "Deputy Sheriff", value: "deputysheriff", index: 74 },
    { label: "Designer", value: "designer", index: 75 },
    { label: "Dietician", value: "dietician", index: 76 },
    { label: "Director Program", value: "directorprogram", index: 77 },
    { label: "Director/Administrator", value: "directoradministrator", index: 78 },
    { label: "Dishwasher", value: "dishwasher", index: 79 },
    { label: "Dispatcher", value: "dispatcher", index: 80 },
    { label: "Doctor", value: "doctor", index: 81 },
    { label: "Drafter", value: "drafter", index: 82 },
    { label: "Driver-Bus/Streetcar", value: "driverbusstreetcar", index: 83 },
    { label: "Driver-Taxi/Limo", value: "drivertaxilimo", index: 84 },
    { label: "Driver-Trucker/Delivery", value: "drivertruckerdelivery", index: 85 },
    { label: "Dry Cleaner/Laundry", value: "drycleanerlaundry", index: 86 },
    { label: "Editor", value: "editor", index: 87 },
    { label: "Electrician", value: "electrician", index: 88 },
    { label: "Elevator Technician/Installer", value: "elevatortechnicianinstaller", index: 89 },
    { label: "Engineer", value: "engineer", index: 90 },
    { label: "Enlisted Military Personnel (E1-E4)", value: "enlistedmilitarypersonnele1e4", index: 91 },
    { label: "Equipment Operator", value: "equipmentoperator", index: 92 },
    { label: "Event Manager/Promoter", value: "eventmanagerpromoter", index: 93 },
    { label: "Examiner", value: "examiner", index: 94 },
    { label: "Executive", value: "executive", index: 95 },
    { label: "Factory Worker", value: "factoryworker", index: 96 },
    { label: "Farm/Ranch Owner", value: "farmranchowner", index: 97 },
    { label: "Farm/Ranch Worker", value: "farmranchworker", index: 98 },
    { label: "Federal Agent/Marshall", value: "federalagentmarshall", index: 99 },
    { label: "Field Service Technician", value: "fieldservicetechnician", index: 100 },
    { label: "Financial Advisor", value: "financialadvisor", index: 101 },
    { label: "Fire Chief", value: "firechief", index: 102 },
    { label: "Fire Fighter/Supervisor", value: "firefightersupervisor", index: 103 },
    { label: "Fisherman", value: "fisherman", index: 104 },
    { label: "Flight Attendant", value: "flightattendant", index: 105 },
    { label: "Floor Layer/Finisher", value: "floorlayerfinisher", index: 106 },
    { label: "Florist", value: "florist", index: 107 },
    { label: "Food Production/Packing", value: "foodproductionpacking", index: 108 },
    { label: "Foreman/Supervisor", value: "foremansupervisor", index: 109 },
    { label: "Furniture Finisher", value: "furniturefinisher", index: 110 },
    { label: "Gaming Officer/Investigator", value: "gamingofficerinvestigator", index: 111 },
    { label: "Graduate Teaching Assistant", value: "graduateteachingassistant", index: 112 },
    { label: "Hair Stylist/Barber", value: "hairstylistbarber", index: 113 },
    { label: "Handyman", value: "handyman", index: 114 },
    { label: "Heat/Air Conditioner Repairman", value: "heatairconditionerrepairman", index: 115 },
    { label: "Heat/Air Technician", value: "heatairtechnician", index: 116 },
    { label: "Highway Patrol Officer", value: "highwaypatrolofficer", index: 117 },
    { label: "Home Health Care", value: "homehealthcare", index: 118 },
    { label: "Hospice Volunteer", value: "hospicevolunteer", index: 119 },
    { label: "Host/Maitre d", value: "hostmaitred", index: 120 },
    { label: "Housekeep/Maid", value: "housekeepmaid", index: 121 },
    { label: "HR Representative", value: "hrrepresentative", index: 122 },
    { label: "Inspector", value: "inspector", index: 123 },
    { label: "Instructor-Vocation", value: "instructorvocation", index: 124 },
    { label: "Insurance CSR", value: "insurancecsr", index: 125 },
    { label: "Investment Banker", value: "investmentbanker", index: 126 },
    { label: "Investor", value: "investor", index: 127 },
    { label: "Jeweler", value: "jeweler", index: 128 },
    { label: "Journalist/Reporter", value: "journalistreporter", index: 129 },
    { label: "Judge/Hearing Officer", value: "judgehearingofficer", index: 130 },
    { label: "Lab Assistant", value: "labassistant", index: 131 },
    { label: "Laborer/Worker", value: "laborerworker", index: 132 },
    { label: "Landscape/Grounds Maintenance", value: "landscapegroundsmaintenance", index: 133 },
    { label: "Legal Assistant/Secretary", value: "legalassistantsecretary", index: 134 },
    { label: "Legislator", value: "legislator", index: 135 },
    { label: "Librarian/Curator", value: "librariancurator", index: 136 },
    { label: "Life Guard", value: "lifeguard", index: 137 },
    { label: "Loan/Escrow Processor", value: "loanescrowprocessor", index: 138 },
    { label: "Logger", value: "logger", index: 139 },
    { label: "Longshoreman", value: "longshoreman", index: 140 },
    { label: "Machine Operator", value: "machineoperator", index: 141 },
    { label: "Maintenance Mechanic", value: "maintenancemechanic", index: 142 },
    { label: "Manager/Supervisor", value: "managersupervisor", index: 143 },
    { label: "Manicurist", value: "manicurist", index: 144 },
    { label: "Marketing Researcher", value: "marketingresearcher", index: 145 },
    { label: "Masseuse", value: "masseuse", index: 146 },
    { label: "Mate/Sailor", value: "matesailor", index: 147 },
    { label: "Mathematician", value: "mathematician", index: 148 },
    { label: "Mayor/City Council", value: "mayorcitycouncil", index: 149 },
    { label: "Mechanic", value: "mechanic", index: 150 },
    { label: "Messenger/Courier", value: "messengercourier", index: 151 },
    { label: "Metalworker", value: "metalworker", index: 152 },
    { label: "Meter Reader", value: "meterreader", index: 153 },
    { label: "Mill Worker", value: "millworker", index: 154 },
    { label: "Miner", value: "miner", index: 155 },
    { label: "Mortician", value: "mortician", index: 156 },
    { label: "Musician/Singer", value: "musiciansinger", index: 157 },
    { label: "Nanny", value: "nanny", index: 158 },
    { label: "NCO (E5-9)", value: "ncoe59", index: 159 },
    { label: "Network Administrator", value: "networkadministrator", index: 160 },
    { label: "Nurse Practitioner", value: "nursepractitioner", index: 161 },
    { label: "Nurse-CNA", value: "nursecna", index: 162 },
    { label: "Nurse-LPN", value: "nurselpn", index: 163 },
    { label: "Nurse-RN", value: "nursern", index: 164 },
    { label: "Officer-Commissioned", value: "officercommissioned", index: 165 },
    { label: "Officer-Warrant", value: "officerwarrant", index: 166 },
    { label: "Oil/Gas Driller/Rig Operator", value: "oilgasdrillerrigoperator", index: 167 },
    { label: "Optometrist", value: "optometrist", index: 168 },
    { label: "Orthodontist", value: "orthodontist", index: 169 },
    { label: "Other", value: "other", index: 170 },
    { label: "Packer", value: "packer", index: 171 },
    { label: "Painter", value: "painter", index: 172 },
    { label: "Paralegal/Law Clerk", value: "paralegallawclerk", index: 173 },
    { label: "Paramedic/EM Technician", value: "paramedicemtechnician", index: 174 },
    { label: "Park Ranger", value: "parkranger", index: 175 },
    { label: "Parking Lot Attendant", value: "parkinglotattendant", index: 176 },
    { label: "Pet Services", value: "petservices", index: 177 },
    { label: "Pharmacist", value: "pharmacist", index: 178 },
    { label: "Photographer", value: "photographer", index: 179 },
    { label: "Physical Therapist", value: "physicaltherapist", index: 180 },
    { label: "Pilot/Captain/Engineer", value: "pilotcaptainengineer", index: 181 },
    { label: "Planner", value: "planner", index: 182 },
    { label: "Plant Manager", value: "plantmanager", index: 183 },
    { label: "Plaster/Drywall/Stucco", value: "plasterdrywallstucco", index: 184 },
    { label: "Plumber", value: "plumber", index: 185 },
    { label: "Police Chief", value: "policechief", index: 186 },
    { label: "Police Detective/Investigator", value: "policedetectiveinvestigator", index: 187 },
    { label: "Police Officer/Supervisor", value: "policeofficersupervisor", index: 188 },
    { label: "Postmaster", value: "postmaster", index: 189 },
    { label: "Principal", value: "principal", index: 190 },
    { label: "Probation Officer", value: "probationofficer", index: 191 },
    { label: "Procurement Specialist", value: "procurementspecialist", index: 192 },
    { label: "Production Planner", value: "productionplanner", index: 193 },
    { label: "Project Manager", value: "projectmanager", index: 194 },
    { label: "Proofreader", value: "proofreader", index: 195 },
    { label: "Property Manager", value: "propertymanager", index: 196 },
    { label: "Public Defender", value: "publicdefender", index: 197 },
    { label: "Public Relations Specialist", value: "publicrelationsspecialist", index: 198 },
    { label: "Purchasing Agent/Manager", value: "purchasingagentmanager", index: 199 },
    { label: "Quality Control", value: "qualitycontrol", index: 200 },
    { label: "Rancher", value: "rancher", index: 201 },
    { label: "Receptionist/Secretary", value: "receptionistsecretary", index: 202 },
    { label: "Repair Technician", value: "repairtechnician", index: 203 },
    { label: "Researcher", value: "researcher", index: 204 },
    { label: "Restaurant Staff", value: "restaurantstaff", index: 205 },
    { label: "Retail Stocker", value: "retailstocker", index: 206 },
    { label: "Rigger", value: "rigger", index: 207 },
    { label: "Roofer", value: "roofer", index: 208 },
    { label: "Safety Inspector", value: "safetyinspector", index: 209 },
    { label: "Sales Representative", value: "salesrepresentative", index: 210 },
    { label: "Sanitation Worker", value: "sanitationworker", index: 211 },
    { label: "Scientist", value: "scientist", index: 212 },
    { label: "Security Guard", value: "securityguard", index: 213 },
    { label: "Senator/Representative", value: "senatorrepresentative", index: 214 },
    { label: "Server/Wait Staff", value: "serverwaitstaff", index: 215 },
    { label: "Serviceman", value: "serviceman", index: 216 },
    { label: "Set-Up Staff", value: "setupstaff", index: 217 },
    { label: "Sheet Metal Worker", value: "sheetmetalworker", index: 218 },
    { label: "Ship/Boat Engineer", value: "shipboatengineer", index: 219 },
    { label: "Ship/Boat Operator", value: "shipboatoperator", index: 220 },
    { label: "Shipping/Receiving Clerk", value: "shippingreceivingclerk", index: 221 },
    { label: "Social Worker", value: "socialworker", index: 222 },
    { label: "Software Developer/Engineer", value: "softwaredeveloperengineer", index: 223 },
    { label: "Soldier", value: "soldier", index: 224 },
    { label: "Stationary Engineer", value: "stationaryengineer", index: 225 },
    { label: "Stock Broker", value: "stockbroker", index: 226 },
    { label: "Store/General Manager", value: "storegeneralmanager", index: 227 },
    { label: "Street Vendor", value: "streetvendor", index: 228 },
    { label: "Surgeon", value: "surgeon", index: 229 },
    { label: "Surveyor", value: "surveyor", index: 230 },
    { label: "Systems Analyst", value: "systemsanalyst", index: 231 },
    { label: "Tax Consultant", value: "taxconsultant", index: 232 },
    { label: "Teacher", value: "teacher", index: 233 },
    { label: "Technical Writer", value: "technicalwriter", index: 234 },
    { label: "Telecom Installer/Tech", value: "telecominstallertech", index: 235 },
    { label: "Television Crew", value: "televisioncrew", index: 236 },
    { label: "Test Administrator", value: "testadministrator", index: 237 },
    { label: "Ticket Taker", value: "tickettaker", index: 238 },
    { label: "Toll Booth Worker", value: "tollboothworker", index: 239 },
    { label: "Tour Guide", value: "tourguide", index: 240 },
    { label: "Trainer/Instructor", value: "trainerinstructor", index: 241 },
    { label: "Translator", value: "translator", index: 242 },
    { label: "Truck Driver", value: "truckdriver", index: 243 },
    { label: "Typist/Transcriber", value: "typisttranscriber", index: 244 },
    { label: "Undertaker", value: "undertaker", index: 245 },
    { label: "Union Worker", value: "unionworker", index: 246 },
    { label: "Utility Worker", value: "utilityworker", index: 247 },
    { label: "Veterinarian", value: "veterinarian", index: 248 },
    { label: "Vice President", value: "vicepresident", index: 249 },
    { label: "Waiter/Waitress", value: "waiterwaitress", index: 250 },
    { label: "Weaver", value: "weaver", index: 251 },
    { label: "Web Designer", value: "webdesigner", index: 252 },
    { label: "Web Developer", value: "webdeveloper", index: 253 },
    { label: "Welder", value: "welder", index: 254 },
    { label: "Wholesale Buyer", value: "wholesalebuyer", index: 255 },
    { label: "Woodworker", value: "woodworker", index: 256 },
    { label: "Writer", value: "writer", index: 257 },
    { label: "Zoologist", value: "zoologist", index: 258 }
];
  


export default empCategories;
