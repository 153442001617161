function byInstallmentNumber(a, b) {
  const compareRegex = /(\d+)\s+(?:Pay|Installments|High)/;
  const installments = [a, b].map(item => item.plan_description.match(compareRegex)[1])
  return installments.reduce((a, b) => (a - b), 0);
}

export function formatMoney(amount) {
  return new Intl.NumberFormat('en-US', {style: 'decimal'}).format(amount);
}

export function monthlyPaymentOption(rate) {
  // return rate.payment_options
  //   .filter(option => option.plan_type === 'monthly')
  //   .sort(byInstallmentNumber)[0]

  let monthlyOptions = rate.payment_options
      .filter(option => option.plan_type === 'monthly')
      .sort(byInstallmentNumber);

  if (monthlyOptions.length > 0) {
    return monthlyOptions[0];
  }

  let payInFullOptions = rate.payment_options
    .filter(option => option.plan_type === 'pay_in_full')
    .sort(byInstallmentNumber);

  // If there are pay_in_full options available, return the first one
  if (payInFullOptions.length > 0) {
    return payInFullOptions[0];
  }
}

export function priceDisplay(option) {
  let amount
  if (option.plan_type === 'pay_in_full') {
    amount = option.premium
  } else {
    amount = option.installment_info.amount + option.installment_info.fee
  }
  return formatMoney(Math.ceil(amount/100));
}

export function payInFullOption(rate) {
  return rate.payment_options.find(item => item.plan_type === 'pay_in_full')
}


export function getMonthlyTotal(option) {
  let deposit                  = option.deposit;
  let monthlyInstallmentAmount = option.installment_info.amount + option.installment_info.fee;

  // Since we round up in the pricing cards, let's also round up here
  // so we can display an accurate discount amount
  deposit = Math.ceil(deposit/100) * 100;
  monthlyInstallmentAmount = Math.ceil(monthlyInstallmentAmount/100) * 100;

  let monthlyTotalAmount = option.installment_info.fee === 0 ? option.premium : deposit + (option.number_of_payments * monthlyInstallmentAmount)
  monthlyTotalAmount = Math.ceil(monthlyTotalAmount/100) * 100;

  return monthlyTotalAmount;
}

export function getFullTotal(option) {
  let fullAmount = option.deposit;
  fullAmount = Math.ceil(fullAmount/100) * 100;

  return fullAmount;
}

// Calculate and return the pay in full discount amount
// which is just the total of the monthly - pay in full total
export function payInFullDiscount(rate) {
  let monthlyOption = monthlyPaymentOption(rate);
  let payFullOption = payInFullOption(rate);

  let monthlyTotal = getMonthlyTotal(monthlyOption)
  return Math.ceil((monthlyTotal - payFullOption.deposit)/ 100) * 100;
}
