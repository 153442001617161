export const STATES = [
  {"value": "IL", "label": "IL"},
  {"value": "IN", "label": "IN"},
  {"value": "MO", "label": "MO"},
  {"value": "KS", "label": "KS"},
  {"value": "IA", "label": "IA"},
  {"value": "AR", "label": "AR"},
  {"value": "TX", "label": "TX"},
  {"value": "WI", "label": "WI"},
  {"value": "OH", "label": "OH"},
  {"value": "PA", "label": "PA"},
  {"value": "OK", "label": "OK"}
]
