import React, { useState, useEffect } from 'react';
import {
    Row, Col,
    Image,
    Container,
    Button
}                                     from "react-bootstrap";
import featureImage                   from "../../images/feature_signature.svg";
import { withTranslation }            from 'react-i18next';
import { useSelector }                from 'react-redux'
import { Helmet } from 'react-helmet'
import history from '../../history';
import { checkState } from '../../services/state-check';
import SpinnerScreen from '../shared/SpinnerScreen';

const Signatures = ({ t }) => {
  const [goToSignaturePage, setGoToSignaturePage] = useState(false)
  const { quote } = useSelector(redux => redux.data)
  const {id,errors} = quote
  const policy_number = quote.policyNumber || quote.policy_number
  const esignUrl = quote.esignUrl ? quote.esignUrl : localStorage.esignUrl
  const [temp, setTemp] = useState(null);

  useEffect(()=> {
    if(esignUrl){
      localStorage.setItem('esignUrl',esignUrl)
    }
    if (goToSignaturePage) {
      if(checkState()){
        window.location.href = `${esignUrl}&extURL=${encodeURIComponent(process.env.REACT_APP_PAGE_URL_ROOT)}/bol/quotes/${encodeURIComponent(id)}/confirmation`
      }
      else{
        setTemp(
          <Row className="justify-content-center mt-2">
            <Col lg={8} className="d-flex justify-content-center" >
              <div  style={{ width: '100%', height: '600px' }}>
                <iframe 
                  src={esignUrl} 
                  width="100%"
                  height="100%" 
                  style={{ border: 'none' }} 
                  title="Signing Document"
                  allowFullScreen
                />
              </div>
            </Col>
          </Row>   
        );
      }
    }
  }, [goToSignaturePage, id, esignUrl])

  useEffect(()=>{
    if(!checkState() && goToSignaturePage){
      function receiveMessage(event) {
        var origin = event.origin || event.originalEvent.origin;
        var data = event.data;
        console.log('data',data, origin);
        if('ESL:MESSAGE:SUCCESS:SIGNER_COMPLETE_REVIEWED' ===`${data}`  ){
          // localStorage.removeItem('esignUrl')
  
          history.push(`/bol/quotes/${id}/confirmation`)
        }
        switch (data) {
              case 'ESL:MESSAGE:REGISTER':
                    event.source.postMessage('ESL:MESSAGE:ACTIVATE_EVENTS', origin);
                    break;
              default:
                    event.source.postMessage(data, origin)
                    break;
        }}
  
        window.addEventListener('message', receiveMessage, false);
        return () => {
          window.removeEventListener('message', receiveMessage);
        };
    }
    
  },[id, temp, goToSignaturePage])

  useEffect(() =>{
    if(!policy_number){
      setTemp(<SpinnerScreen title="Getting documents to Sign" />);
    }else{
      setTemp(null)
    }
    if(!policy_number && errors){
      history.push('/contact-us')
    }
  },[policy_number,errors])

  return (
    <Container>
      <Helmet>
        <title>Signatures | InsureOnline.com</title>
      </Helmet>
      {temp ? temp : 
      (<>
        <Row className="justify-content-center">
          <Col lg={6} className="d-flex flex-column justify-content-center">
            <h1>{t("signaturePage.header")}</h1>
            <p>{t("signaturePage.message")}</p>
          </Col>
        </Row>
        <Row className="justify-content-center mt-5">
          <Col lg={6} className="d-flex justify-content-center" >
            <div  style={{width:"304px", height:'304px', backgroundColor:'#FEEFE9'}} className="rounded-circle d-flex justify-content-center align-items-center">
              <Image src={featureImage} width="195px" height="169px" />
            </div>
          </Col>
        </Row>
        <Row className='justify-content-center mt-5'>
          <Col lg={5}>
            <Button className="rounded-pill mb-3" size='lg' variant="primary" type="submit" block disabled={false} onClick={()=>setGoToSignaturePage(true)}>
              {t("signaturePage.cta")}
            </Button>
          </Col>
        </Row>
      <Row>
        <Col className="text-center mt-5 mb-2">
          <p><strong>{t("signaturePage.footer.subheading")}</strong></p>
          <p>{t("signaturePage.footer.submessage")}</p>
          {/* <p>{t("signaturePage.footer.line2")}</p> */}
          <p><a href={`te:${t("signaturePage.footer.phoneNumber")}`} className="text-dark">{ t("signaturePage.footer.phoneDisplay")}</a></p>
          <p><a href={`mailto:${t("signaturePage.footer.email")}`} className="text-primary">{ t("signaturePage.footer.email")}</a></p>
          <p>6640 S. Cicero Ave<br/>Bedford Park, IL 60638</p>
        </Col>
      </Row>
      </>)}
    </Container>
  )
}

export default withTranslation(['common'])(Signatures);